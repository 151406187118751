import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  colors: {
    primary: {
      color_01: 'rgb(252, 247, 222)',
      color_02: 'rgb(253, 224, 94)',
      color_03: 'rgb(249, 203, 4)',
      color_04: 'rgb(181, 148, 3)',
      color_05: '#FFEBD2',
      shadow_01: 'rgba(255, 153, 0, 0.2)',
    },
    positive: {
      color_01: 'rgb(222, 252, 233)',
      color_02: 'rgb(153, 214, 175)',
      color_03: 'rgb(96, 191, 131)',
      color_04: 'rgb(58, 146, 90)',
      color_05: '#E6F2EB',
      color_06: 'rgba(59, 176, 78, 1)',
      shadow_01: 'rgba(58, 146, 90, 0.2)',
    },
    neutral: {
      color_01: 'rgb(255, 253, 250)',
      color_02: 'rgb(247, 245, 242)',
      color_02_01: 'rgba(242, 242, 242, 1)',
      color_03: 'rgb(237, 235, 232)',
      color_04: 'rgb(190, 188, 185)',
      color_05: 'rgb(112, 110, 107)',
      color_05_opacity: 'rgba(112, 110, 107, 0.81)',
      color_06: 'rgb(19, 17, 14)',
      shadow_00: 'rgba(0, 0, 0, 0.16)',
      shadow_01: 'rgba(19, 17, 14, 0.1)',
      shadow_03: 'rgba(0, 0, 0, 0.05)',
      shadow_04: 'rgba(0, 0, 0, 0.15)',
    },
    alert: {
      color_01: 'rgb(252, 226, 222)',
      color_02: 'rgb(240, 178, 170)',
      color_03: 'rgb(240, 134, 120)',
      color_04: 'rgb(240, 51, 25)',
      color_05: 'rgb(201, 32, 29)',
    },
    focus: {
      color_01: 'rgb(255, 164, 17)',
    },
    chart: {
      a: {
        color_01: 'rgb(9, 109, 134)',
        gradient_01: '#05f0e0',
        gradient_02: '#096d86',
      },
      b: {
        color_01: '#fb8f60',
      },
    },
    accent: {
      a: {
        color_03: 'rgba(5, 153, 237, 1)',
        color_01: 'rgb(235, 235, 255)',
        color_02: 'rgb(5, 6, 250)',
        shadow_01: 'rgba(7, 7, 180, 0.2)',
      },
      b: {
        color_01: 'rgb(251, 235, 255)',
        color_02: 'rgb(146, 3, 181)',
        color_03: '#D2ADFC',
        color_04: 'rgba(160, 128, 255, 1)',
        shadow_01: 'rgba(146, 3, 181, 0.2)',
      },
      c: {
        color_01: 'rgb(215, 245, 255)',
        color_02: 'rgb(6, 89, 116)',
        color_03: 'rgb(233, 241, 255)',
        shadow_01: 'rgba(6, 89, 116, 0.2)',
      },
    },
    others: {
      transparent: 'rgba(0, 0, 0, 0)',
    },
    force: {
      primary: {
        background: 'rgb(255, 255, 191)',
        basis: 'rgb(255, 255, 0)',
        gradient: 'linear-gradient(180deg, #FEFE55 0%, #F2F209 100%)',
        text: 'rgb(128, 117, 0)',
      },
    },
    semantic: {
      info: {
        basis: 'rgb(4, 122, 241)',
      },
    },
    interface: {
      label: {
        primary: 'rgba(0, 0, 0, .9)',
      },
    },
    error: {
      label: {
        primary: 'rgba(195, 51, 43, 1)',
      },

      icon: {
        primary: 'rgba(229, 60, 50, 1)',
      },
    },
  },
};

export default defaultTheme;
