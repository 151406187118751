import { KeyboardEvent } from 'react';

export function validateMinimumQuantity(
  options: { value: string }[],
  minimumQuantity: number
): boolean {
  const hasTwoItems = options?.length >= minimumQuantity;
  const isValid = hasTwoItems;

  return isValid;
}

export function validateDuplicatedValues(options: { value: string }[]): boolean {
  const hasDuplicatedValues =
    options?.filter((optionValue, optionIndexValue) =>
      options.some(
        (option, optionIndex) =>
          option.value === optionValue.value && optionIndexValue !== optionIndex
      )
    ).length > 0;
  const isValid = !hasDuplicatedValues;

  return isValid;
}

export function preventFormSubmitOnEnter(event: KeyboardEvent<HTMLFormElement>): void {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}
