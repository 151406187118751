import { ProfileId } from '../models/user';

export const DEFAULT_PROFILE = {
  id: ProfileId.Bdr,
  title: 'BDR',
};

export const EMPTY_VALUE = {
  id: '',
  name: '',
};
