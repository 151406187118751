import { useAuthenticationService } from 'admin-portal-shared-services';
import { AxiosResponse } from 'axios';
import { SERVICE_FRONTLINE_VENDOR_GROUPS, getServiceDomain } from '../../../consts/endpoint';
import { GetGroupsContent } from '../../../models/group';
import { isClusterEuUser } from '../../../utils/validations';
import api from '../../api';

// eslint-disable-next-line react-hooks/rules-of-hooks
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
const isClusterEu = isClusterEuUser(userCountry);

export const getGroups = async (): Promise<AxiosResponse<GetGroupsContent>> =>
  api.get({
    url: `${getServiceDomain(isClusterEu)}${SERVICE_FRONTLINE_VENDOR_GROUPS}/groups`,
  });
