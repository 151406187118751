import { useHasPermission } from 'admin-portal-shared-services';
import { getPermissionGroup, permissionsGroups } from './normalizePermissionsScopes';

export const usePermissions = (
  permission: keyof typeof permissionsGroups,
  mode?: 'AND' | 'OR'
): boolean => {
  const permissionGroup = getPermissionGroup(permission);

  const response = useHasPermission(permissionGroup, mode);

  return response;
};
