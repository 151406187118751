import { LoadingDots } from '@hexa-ui/components';
import React from 'react';
import { MenuProps } from 'react-select';
import { Styled } from './Menu.styles';

function Menu({
  children,
  innerProps,
  innerRef,
  isLoading,
  options,
}: Readonly<Partial<MenuProps>>): React.JSX.Element {
  return (
    <Styled.AutoCompleteMenu data-testid="autocomplete-select-menu" {...innerProps} ref={innerRef}>
      <>
        {children}

        {!!options?.length && isLoading && (
          <Styled.LoadingWrapper data-testid="loading-dots">
            <LoadingDots />
          </Styled.LoadingWrapper>
        )}
      </>
    </Styled.AutoCompleteMenu>
  );
}

export default Menu;
