export enum Scopes {
  BeesForceAdminSimpleTaskTemplateRead = 'BeesForceAdmin.SimpleTaskTemplate.Read',
  BeesForceAdminSimpleTaskTemplateWrite = 'BeesForceAdmin.SimpleTaskTemplate.Write',
  BeesForceAdminSimpleTaskSubmissionRead = 'BeesForceAdmin.SimpleTaskSubmission.Read',
  BeesForceAdminSimpleTaskSubmissionWrite = 'BeesForceAdmin.SimpleTaskSubmission.Write',
  BeesForceAdminSurveyTaskTemplateRead = 'BeesForceAdmin.SurveyTaskTemplate.Read',
  BeesForceAdminSurveyTaskTemplateWrite = 'BeesForceAdmin.SurveyTaskTemplate.Write',
  BeesForceAdminSurveyTaskSubmissionWrite = 'BeesForceAdmin.SurveyTaskSubmission.Write',
  BeesForceAdminSurveyTaskSubmissionRead = 'BeesForceAdmin.SurveyTaskSubmission.Read',
  BeesForceAdminPriorizatorWrite = 'BeesForceAdmin.Priorizator.Write',
  BeesForceAdminPriorizatorRead = 'BeesForceAdmin.Priorizator.Read',
  BeesForceAdminUserManagementWrite = 'BeesForceAdmin.UserManagement.Write',
  BeesForceAdminUserManagementRead = 'BeesForceAdmin.UserManagement.Read',
  BeesForceAdminPhotoTaskTemplateWrite = 'BeesForceAdmin.PhotoTaskTemplate.Write',
  BeesForceAdminPhotoTaskTemplateRead = 'BeesForceAdmin.PhotoTaskTemplate.Read',
  BeesForceAdminPhotoTaskSubmissionWrite = 'BeesForceAdmin.PhotoTaskSubmission.Write',
  BeesForceAdminPhotoTaskSubmissionRead = 'BeesForceAdmin.PhotoTaskSubmission.Read',
  BeesForceAdminPhotoPriorizatorWrite = 'BeesForceAdmin.PhotoPriorizator.Write',
  BeesForceAdminPhotoPriorizatorRead = 'BeesForceAdmin.PhotoPriorizator.Read',
  BeesForceAdminTaskCancellationRead = 'BeesForceAdmin.TaskCancellation.Read',
  BeesForceAdminTaskCancellationWrite = 'BeesForceAdmin.TaskCancellation.Write',
  BeesForceOperationsGlobalRead = 'BeesForceAdmin.OperationsGlobal.Read',
  BeesForceTaskManagementRead = 'BeesForceAdmin.TaskManagement.Read',
  BeesForceTaskManagementWrite = 'BeesForceAdmin.TaskManagement.Write',
}
