import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATH_BASE } from '../../consts/route';
import { FormTypeId } from '../../models/task-group';
import {
  ButtonCancelTaskForm,
  ButtonSubmitTaskForm,
  FooterTaskFormElement,
} from './FooterTaskGroupForm.styles';

export interface FooterTaskGroupFormProps {
  formType: FormTypeId;
  isLoading?: boolean;
  disabled?: boolean;
  onClickSubmit?: () => void;
  onClickCancel?: () => void;
}

const FooterTaskGroupForm = ({
  isLoading = false,
  disabled = false,
  onClickSubmit,
  onClickCancel,
  formType,
}: FooterTaskGroupFormProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  function handleCancel() {
    !!onClickCancel && onClickCancel();
    navigate(PATH_BASE);
  }

  return (
    <FooterTaskFormElement data-testid="footer-task-form">
      <ButtonCancelTaskForm
        data-testid="button-cancel-task-group-form"
        onClick={handleCancel}
        variant={'secondary'}
      >
        {formatMessage({ id: 'app.taskGroups.button.taskGroupButtonCancel' })}
      </ButtonCancelTaskForm>

      <ButtonSubmitTaskForm
        data-testid="button-submit-task-group-form"
        type="submit"
        isLoading={isLoading}
        onClick={onClickSubmit}
        disabled={disabled}
      >
        {formatMessage({ id: `app.taskGroups.button.taskGroupButton${formType}` })}
      </ButtonSubmitTaskForm>
    </FooterTaskFormElement>
  );
};

export default FooterTaskGroupForm;
