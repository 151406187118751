import { Paragraph } from '@hexa-ui/components';
import { ChevronDown } from '@hexa-ui/icons';
import { styled, theme } from '@hexa-ui/theme';

const AutoCompleteInput = styled('div', {
  width: '100%',
  paddingLeft: '8px',
  border: '1px solid $interfaceSurfaceSecondary',
  display: 'flex',
  height: '40px',
  cursor: 'text',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '$neutral0',
  fontSize: '16px',
  fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
  fontWeight: '18px',
  color: '$interfaceLabelPrimary',
  borderRadius: '8px',
  paddingRight: '18px',
  '&:hover': {
    border: '1px solid $interfaceLabelPrimary',
  },
});

const AutoCompleteOption = styled('div', {
  variants: {
    isFocused: {
      true: {
        backgroundColor: '$brandAccentNeutralBackground',
      },
    },
    isSelected: {
      true: {
        backgroundColor: '$brandAccentNeutralBasis',
      },
    },
  },
  flexGrow: 1,
  minHeight: '56px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '18px',
  background: '$interfaceSurfaceClear',
  color: '$interfaceLabelPrimary',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontFamily: '$paragraph',
  cursor: 'pointer',
});

const AutoCompleteChevronDown = styled(ChevronDown, {
  fill: '$interfaceLabelPrimary !important',
  width: '16px !important',
  height: '16px !important',
  cursor: 'pointer',
});

const ContainerOptions = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '276px',
  width: '100%',
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const Title = styled(Paragraph, {
  fontSize: '14px !important',
  fontWeight: 'bold !important',
  lineHeight: '16px !important',
  display: 'flex',
  flexDirection: 'row',
});

const Hint = styled(Paragraph, {
  fontSize: '12px !important',
  fontWeight: 'normal !important',
  lineHeight: '18px !important',
  color: 'rgba(0, 0, 0, 0.55) !important',
});

const Optional = styled(Paragraph, {
  fontSize: '12px !important',
  fontWeight: 'normal !important',
  lineHeight: '18px !important',
  color: 'rgba(0, 0, 0, 0.55) !important',
  paddingLeft: '4px',
});

const PlaceholderContainer = styled('div', {
  variants: {
    isDisabled: {
      true: {
        color: theme.colors.neutral50,
      },
      false: {
        color: theme.colors.neutral100,
      },
    },
  },
});

export const sizes = {
  small: '140px',
  large: '420px',
  medium: '210px',
  fill: '100%',
};

export const Styled = {
  AutoCompleteInput,
  AutoCompleteOption,
  AutoCompleteChevronDown,
  ContainerOptions,
  Container,
  Title,
  Hint,
  PlaceholderContainer,
  Optional,
};
