import { Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PATH_TASK_GROUPS, PATH_TASK_LIST } from '../consts/route';
import { usePermissions } from '../hooks/usePermissions/usePermissions';
import Page404 from '../pages/Page404';
import PageError from '../pages/PageError';
import PageLoading from '../pages/PageLoading';
import routesTaskGroups from './../apps/task-groups/routes';
import routesTaskManagement from './../routes';

const PageFallback = <PageLoading />;

const Router = (): JSX.Element => {
  const location = useLocation();
  const canAcessTaskManagement = usePermissions('access-task-management', 'OR');
  const canAccessTaskGroups = usePermissions('access-task-groups', 'OR');
  const isAccessTaskManagementRoute = location.pathname.startsWith(PATH_TASK_LIST);
  const isAccessTaskGroupsRoute = location.pathname.startsWith(PATH_TASK_GROUPS);

  const { formatMessage } = useIntl();
  const [pageAccessError, setPageAccessError] = useState(false);

  const errorMessage = {
    message: formatMessage({ id: 'message.error.userWithoutGroups' }),
  };

  useEffect(() => {
    if (!canAcessTaskManagement && isAccessTaskManagementRoute) {
      setPageAccessError(true);
    }

    if (!canAccessTaskGroups && isAccessTaskGroupsRoute) {
      setPageAccessError(true);
    }
  }, [
    canAccessTaskGroups,
    canAcessTaskManagement,
    isAccessTaskGroupsRoute,
    isAccessTaskManagementRoute,
  ]);

  return (
    <Routes>
      {canAcessTaskManagement &&
        routesTaskManagement.map((props) => <Route key={props.path} {...props} />)}

      {canAccessTaskGroups &&
        routesTaskGroups.map((props) => <Route key={props.path} {...props} />)}

      <Route
        path="*"
        element={
          <Suspense fallback={PageFallback}>
            {pageAccessError ? (
              <PageError error={errorMessage} onRetry={() => window.location.reload()} />
            ) : (
              <Page404 />
            )}
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
