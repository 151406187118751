import { Button } from '@hexa-ui/components';
import styled from 'styled-components';

export const FooterTaskFormElement = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin: 40px 0;
`;

export const ButtonCancelTaskForm = styled(Button)``;

export const ButtonSubmitTaskForm = styled(Button)`
  pointer-events: ${({ isLoading }) => isLoading && 'none'};
`;
