import React from 'react';
import { Country } from '../../models/country';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string;
  SEGMENT_KEY: Record<Country, string> | undefined;
};

export const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  SEGMENT_KEY: {
    [Country.BR]: '',
    [Country.US]: '',
    [Country.AR]: '',
    [Country.CO]: '',
    [Country.DO]: '',
    [Country.EC]: '',
    [Country.MX]: '',
    [Country.PA]: '',
    [Country.PE]: '',
    [Country.ZA]: '',
    [Country.HN]: '',
    [Country.SV]: '',
  },
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
