import MessageMap from '../../../../i18n/i18n.d';

const es419: MessageMap = {
  title: {
    page: {
      taskGroupsList: 'Grupos de tareas',
      taskGroupCreate: 'Creación de grupo de tarea',
      taskGroupUpdate: 'Edición de grupo de tarea',
    },
  },
  button: {
    taskGroupButtonCreate: 'Crear grupo',
    taskGroupButtonUpdate: 'Editar grupo',
    taskGroupButtonCancel: 'Volver',
    editTaskGroup: 'Editar grupo',
    viewGroup: 'Ver grupo',
  },
  fields: {
    label: {
      taskList: {
        selectProfile: 'Perfil',
        taskGroupNextSync: 'Los cambios realizados tendrán efecto en la próxima sincronización.',
        priority: 'Prioridad: ',
        localModel: 'Modelo local',
      },
      taskGroupSelect: 'Grupo de tareas',
    },
    taskGroupForm: {
      label: {
        profile: 'Perfil',
        name: 'Nombre',
        description: 'Descripción',
        maximumTasks: 'Máximo de tareas en este grupo ',
        isLocalModel: 'Tareas de modelo local',
      },
    },
    placeholder: {
      profile: 'Seleccione o perfil',
      name: 'Ingrese nombre del grupo',
      description: 'Ingrese la descripción del grupo',
      maximumTasks: 'Ej. 20',
      taskGroupPriority: 'Ej. 3',
    },
  },
  message: {
    error: {
      taskGroupsNotFound:
        'Aún no se han creado grupos de tareas. Comience a crearlos en Crear grupo.',
    },
    info: {
      effectivenessMultipleConditions:
        'La característica seleccionada es del tipo cobertura por lo tanto, solo puede emparejarse con el tipo cobertura.',
      frequencyDisabled:
        'La característica seleccionada requiere un valor predeterminado fijo para la frecuencia.',
    },
  },
  toast: {
    taskGroupError: 'Error al cargar el grupo de tareas. Inténtelo de nuevo más tarde.',
    error: {
      taskGroupsPriority:
        'No se puede actualizar la prioridad del grupo de tareas. Intente de nuevo más tarde.',
      create: 'Un error ocurrió al crear el grupo de tareas. Inténtelo de nuevo más tarde.',
      update: 'Un error ocurrió al actualizar el grupo de tareas. Inténtelo de nuevo más tarde.',
    },
    success: {
      taskGroupsPriority: 'Se ha ajustado la prioridad de {total} grupos de tareas.',
      create: 'Nuevo grupo de tareas creado con éxito.',
      update: 'Grupo de tareas actualizado con éxito.',
    },
  },
};

export default es419;
