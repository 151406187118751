import { UserMetadata } from 'admin-portal-shared-services';

export enum AppHeaderInfo {
  VendorId = 'vendorId',
  Country = 'country',
  StoredAppHeaderInfo = 'storedAppHeaderInfo',
}

export enum ProfileId {
  Bdr = 'BDR',
  NegBdr = 'NEGBDR',
  Merchandiser = 'MERCHANDISER',
}

export interface Profile {
  id: string;
  title: string;
}
export interface UserState {
  profile: Profile;
  isMultiProfile: boolean;
  availableProfiles: Profile[];
  vendorId: string;
  sessionId: string;
  country: string;
  groups: { groups: string }[];
  metadata: UserMetadata;
}

export type VendorOptimizelyEntry = Record<string, { title: ProfileId; categories: string }>;

export type ProfileOptimizelyEntry = [ProfileId, VendorOptimizelyEntry];
