import { Error404 } from '@hexa-ui/components';
import { DocumentTitle } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATH_BASE } from '../../consts/route';
import { HideAppHeader } from '../../styles/GlobalStyles';

import { PageErrorWrapper } from './Page404.styles';

const Page404 = (): JSX.Element => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <>
      <DocumentTitle>{formatMessage({ id: 'title.pageNotFound' })}</DocumentTitle>
      <PageErrorWrapper>
        <Error404
          buttonClickHandler={() => {
            navigate(PATH_BASE);
          }}
          buttonText={formatMessage({ id: 'button.backToHome' })}
          headerText={formatMessage({ id: 'message.error.notFound' })}
          subHeaderText={formatMessage({ id: 'message.error.notFoundSuggestion' })}
        />
      </PageErrorWrapper>
      <HideAppHeader $hasAppHeader={false} $hasBreadcrumb={false} />
    </>
  );
};

export default Page404;
