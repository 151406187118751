import { styled } from '@hexa-ui/theme';

const AutoCompleteMenu = styled('div', {
  zIndex: 50,
  background: '$neutral0',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  border: '$interfaceForegroundSurfaceSecondary',
  borderRadius: '18px',
  boxShadow: '$1',
  marginTop: '$half',
  position: 'absolute',
  margin: '4px 0 0 0',
  paddingRight: '$basis',
  paddingBottom: '10px',
  paddingTop: '10px',
  overflow: 'auto',
  '& div': {
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: '$neutral0',
      border: 'solid 3px $neutral0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '$interfaceSurfaceSecondary',
      backgroundClip: 'padding-box',
      borderRadius: 10,
    },
  },
});

const LoadingWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '5px',
  right: '50%',
});

export const Styled = {
  AutoCompleteMenu,
  LoadingWrapper,
};
