import { Paragraph } from '@hexa-ui/components';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface OperationButtonProps {
  disabled: boolean;
  width?: CSSProperties['width'];
}
export const OperationContainer = styled.div<OperationButtonProps>`
  width: ${(props) => props.width};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const OperationButton = styled.button<OperationButtonProps>`
  all: unset;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--colors-interfaceSurfaceSecondary);
  background-color: ${(props) =>
    props.disabled ? ' rgba(20, 20, 20, 0.08)' : 'var(--colors-neutral0)'};
  padding: 8px var(--space-4);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) =>
    props.disabled ? ' rgba(20, 20, 20, 0.32)' : 'var(--colors-interfaceLabelPrimary)'};

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: var(--space-2);
    color: ${(props) =>
      props.disabled ? ' rgba(20, 20, 20, 0.32)' : 'var(--colors-interfaceLabelPrimary)'};
  }
`;

export const OperationLabel = styled(Paragraph)`
  font-size: var(--fontSizes-2);
  padding-bottom: var(--space-1);
  font-weight: var(--fontWeights-semibold);
  line-height: var(--lineHeights-3);
`;

export const OperationFrame = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoIconContainer = styled.div`
  height: 24px;
`;

export const ContainerOperationsButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
