import { CategorySearch, Clipboard, List2, Users } from '@hexa-ui/icons';
import { useHasPermission, useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import {
  PATH_AUDIENCE_BUILDER,
  PATH_OPERATIONS_PANEL,
  PATH_TASK_GROUPS,
  PATH_TASK_LIST,
  PATH_TASK_LIST_V1,
} from '../../consts/route';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { Scopes } from '../../models/scopes';
import { SidebarStyles } from './Sidebar.styles';

export const SidebarIcons = {
  Tasks: (): JSX.Element => <Clipboard />,
  OperationsPanel: (): JSX.Element => <CategorySearch />,
  TaskGroups: (): JSX.Element => <List2 />,
  Users: (): JSX.Element => <Users />,
};

const Sidebar = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const canAccessV1 = usePermissions('v-one-card');
  const canAccessV2 = usePermissions('v-two-card');
  const canAccessTaskGroups = usePermissions('access-task-groups', 'OR');
  const canAccessAudienceBuilder = useHasPermission(Scopes.BeesForceTaskManagementWrite);

  const sidebarItems = [];

  if (canAccessV2) {
    sidebarItems.push({
      id: 'tasks-v2',
      title: formatMessage({ id: 'sidebar.title.pageTasks' }),
      icon: SidebarIcons.Tasks,
      path: PATH_TASK_LIST,
    });
  }

  if (canAccessV1) {
    sidebarItems.push({
      id: 'tasks-v1',
      title: formatMessage({ id: 'sidebar.title.pageTasks' }),
      icon: SidebarIcons.Tasks,
      path: PATH_TASK_LIST_V1,
    });
  }

  if (useHasPermission(Scopes.BeesForceOperationsGlobalRead)) {
    sidebarItems.push({
      id: 'operation',
      title: formatMessage({ id: 'sidebar.title.pageOperations' }),
      icon: SidebarIcons.OperationsPanel,
      path: PATH_OPERATIONS_PANEL,
    });
  }

  if (canAccessTaskGroups) {
    sidebarItems.push({
      id: 'task-groups',
      title: formatMessage({ id: 'sidebar.title.pageTaskGroups' }),
      icon: SidebarIcons.TaskGroups,
      path: PATH_TASK_GROUPS,
    });
  }

  if (canAccessAudienceBuilder) {
    sidebarItems.push({
      id: 'audience-builder',
      title: formatMessage({ id: 'sidebar.title.pageAudienceBuilder' }),
      icon: SidebarIcons.Users,
      path: PATH_AUDIENCE_BUILDER,
    });
  }

  useSidebar({
    items: sidebarItems,
    utils: [],
  });

  return <SidebarStyles $hasAppHeader={false} $hasBreadcrumb={false} />;
};

export default Sidebar;
