/* eslint-disable no-prototype-builtins */
enum Scopes {
  'TaskManagementWrite' = 'BeesForceAdmin.TaskManagement.Write',
  'TaskManagementRead' = 'BeesForceAdmin.TaskManagement.Read',
  'TaskGroupsWrite' = 'BeesForceAdmin.TaskGroups.Write',
  'TaskGroupsRead' = 'BeesForceAdmin.TaskGroups.Read',
  'VTwoCardRead' = 'BeesForceAdmin.VTwoCard.Read',
  'VOneCardRead' = 'BeesForceAdmin.VOneCard.Read',
}

export const permissionsGroups = {
  admin: [Scopes.TaskManagementWrite],
  'not-admin': [Scopes.TaskManagementRead],
  'task-groups-admin': [Scopes.TaskGroupsWrite],
  'task-groups-not-admin': [Scopes.TaskGroupsRead],
  'v-one-card': [Scopes.VOneCardRead],
  'v-two-card': [Scopes.VTwoCardRead],
  'access-task-management': [Scopes.TaskManagementWrite, Scopes.TaskManagementRead],
  'access-task-groups': [Scopes.TaskGroupsWrite, Scopes.TaskGroupsRead],
};

export const getPermissionGroup = (permission: keyof typeof permissionsGroups): string[] =>
  permissionsGroups.hasOwnProperty(permission) ? permissionsGroups[permission] : null;
