import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getActivityProfiles } from '../../api/services/frontline';
import { QUERY_KEY_ACTIVITY_PROFILES } from '../../consts/query';
import { ActivityProfile, ActivityProfileList, SearchParamNameTask } from '../../models/activity';
import { Profile } from '../../models/user';
import { setAvailableProfiles, setProfile } from '../../redux/slices/userSlice';
import useAppDispatch from '../useAppDispatch';
import useGroupData from '../useContext/useGroupData/useGroupData';

function useMultiProfileFlag(): {
  isSuccessMultiProfileFlag: boolean;
  isMultiProfileLoading: boolean;
} {
  const dispatch = useAppDispatch();

  const [isSuccessMultiProfileFlag, setIsSuccessMultiProfileFlag] = useState<boolean>(false);

  const { groupData } = useGroupData();
  const { vendorGroupId } = groupData;

  const url = window.location.href;
  const params = new URLSearchParams(new URL(url).search);
  const profileQueryParam = params.get(SearchParamNameTask.Profile);

  const { data: dataActivityProfile, isLoading } = useQuery<
    AxiosResponse<ActivityProfileList>,
    AxiosError
  >([QUERY_KEY_ACTIVITY_PROFILES, vendorGroupId], ({ signal }) => getActivityProfiles({ signal }), {
    enabled: !!vendorGroupId,
  });

  const formatProfiles = useCallback(
    (profilesFlag: ActivityProfile[]) => {
      const availableProfiles: Profile[] = [];
      profilesFlag.forEach((profile) => {
        availableProfiles.push({
          id: profile.profileId,
          title: profile.title,
        });
      });
      return availableProfiles;
    },
    [dataActivityProfile]
  );

  useEffect(() => {
    if (dataActivityProfile) {
      const profilesFlag = dataActivityProfile?.data?.content;

      const avaliableProfiles = formatProfiles(profilesFlag);

      dispatch(setAvailableProfiles(avaliableProfiles));

      if (!!!profileQueryParam) dispatch(setProfile(avaliableProfiles[0]));
      else {
        const availableProfileInUrl = avaliableProfiles.find(
          (profile) => profile.id === profileQueryParam
        );

        dispatch(setProfile(availableProfileInUrl));
      }
      setIsSuccessMultiProfileFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataActivityProfile]);

  return {
    isSuccessMultiProfileFlag,
    isMultiProfileLoading: isLoading,
  };
}

export default useMultiProfileFlag;
