import { useQueryClient } from '@tanstack/react-query';
import { useAppHeader, UserMetadata, useUserMetadata } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { axiosInstance } from '../../api';
import { setCountry, setGroups, setUserMetadata, setVendorId } from '../../redux/slices/userSlice';
import useAppDispatch from '../useAppDispatch';
import useGroupData from '../useContext/useGroupData/useGroupData';

function useAppUserMetadata(): {
  mutateUserMetadata: import('swr').KeyedMutator<UserMetadata>;
  isSuccessMetadata: boolean;
  hasErrorMetadata: boolean;
  vendorGroupId: string;
  metadata: UserMetadata;
} {
  const dispatch = useAppDispatch();
  const {
    data: metadata,
    isLoading: isLoadingMetadata,
    hasError: hasErrorMetadata,
    mutateUserMetadata,
  }: any = useUserMetadata();

  const {
    groupData: { vendorGroupId },
  } = useGroupData();

  const isSuccessMetadata = !hasErrorMetadata && !isLoadingMetadata && !!metadata;
  const [appHeaderConfig] = useAppHeader();

  const groups = metadata?.authorization?.groups;
  const hasVendorId = isSuccessMetadata && !!vendorGroupId;
  const hasTimezone = isSuccessMetadata && !!metadata?.profile?.timezone;
  const hasGroups = isSuccessMetadata && metadata?.authorization?.groups.length > 0 && groups;
  const queryClient = useQueryClient();
  const { selectedCountry } = appHeaderConfig;

  useEffect(() => {
    axiosInstance.defaults.headers['vendorGroupId'] = vendorGroupId;
    dispatch(setVendorId(vendorGroupId));
    dispatch(setCountry(selectedCountry));
    queryClient.invalidateQueries();
  }, [dispatch, queryClient, selectedCountry, vendorGroupId]);

  useEffect(() => {
    if (isSuccessMetadata) {
      dispatch(setUserMetadata(metadata));
      if (hasTimezone) axiosInstance.defaults.headers['timezone'] = metadata?.profile?.timezone;
      if (hasGroups) dispatch(setGroups(groups));
    }
  }, [isSuccessMetadata, metadata, dispatch, hasTimezone, hasVendorId, hasGroups, groups]);

  return {
    isSuccessMetadata,
    mutateUserMetadata,
    hasErrorMetadata,
    vendorGroupId,
    metadata,
  };
}

export default useAppUserMetadata;
