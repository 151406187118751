export enum TaskGroupFormField {
  Profile = 'profileId',
  Name = 'name',
  Description = 'description',
  MaximumTasks = 'maxTasksPerVisit',
  VendorGroupId = 'vendorGroupId',
  IsLocalModel = 'isLocalModel',
}

export enum FormTypeId {
  Create = 'Create',
  Update = 'Update',
  Duplicate = 'Duplicate',
}

export interface PayloadTaskGroup {
  profileId: string;
  name: string;
  description: string;
  maxTasksPerVisit: number;
  vendorGroupId: string;
  isLocalModel: boolean;
}

export interface PayloadUpdateTaskGroup {
  profileId?: string;
  name: string;
  description: string;
  maxTasksPerVisit: number;
  isLocalModel: boolean;
}

export interface UpdateTaskGroup {
  body: PayloadUpdateTaskGroup;
  taskGroupId: string;
  vendorGroupId: string;
}

export interface PayloadPriorityTaskGroup {
  priorities: Priority[];
  profileId: string;
}

interface Priority {
  activityTemplateGroupId: string;
  priority: number;
}

export interface CreateTaskGroupResponse {
  id: string;
}

export interface PriorityTaskGroup {
  body: PayloadPriorityTaskGroup;
}

export interface CreateTaskGroup {
  body: PayloadTaskGroup;
}

export interface TaskGroupListContent {
  isLocalModel: boolean;
  activityTemplateGroupId: string;
  name: string;
  description: string;
  profileId: string;
  vendorGroupId: string;
  priority: number;
  maxTasksPerVisit: number;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  showPriority?: boolean;
  isPriorityChanged?: boolean;
}
export interface TaskGroupCardContent {
  content: TaskGroupListContent[];
}
export interface GetTaskGroupList {
  vendorGroupId: string;
  profileId: string;
  signal?: AbortSignal;
  excludeLocalModels?: boolean;
}

export interface GetTaskGroupListResponse {
  activityTemplateGroupId: string;
  createdAt: string;
  description: string;
  maxTasksPerVisit: number;
  name: string;
  priority: number;
  profileId: string;
  updatedAt: string;
  updatedBy: string;
  vendorGroupId: string;
  isLocalModel: boolean;
}
