import { LoadingDots, Paragraph, Tooltip } from '@hexa-ui/components';
import { useEffect, useRef, useState } from 'react';
import Select, { GetOptionLabel, GetOptionValue, InputActionMeta, MenuProps } from 'react-select';

import { Styled, sizes } from './Autocomplete.styles';
import { AutocompleteProps, DefaultValues } from './Autocomplete.types';
import DropdownIndicator from './components/DropdownIndicator/DropdownIndicator';
import Menu from './components/Menu/Menu';
import Option from './components/Option/Option';
import { waitForElementToExist } from './utils/utils';

export const COPY_DECK = {
  genericError: {
    errorTitle: 'Page could not be loaded',
    errorMessage: "We're not sure what happened.",
    action: 'Try again',
    buttonAction: 'Reload list',
    genericErrorTitle: 'List could not be loaded',
    subTitleError: "We're not sure what happened. Try reloading.",
  },
  audience: {
    cardTitle: 'Audiences',
    estimatedPocs: 'Estimated targeted POCs:',
    select: 'Select',
  },
  autocomplete: {
    noResultsFound: 'No results found',
    noDataAvailable: 'There are no data available',
  },
};

function Autocomplete<T = DefaultValues>({
  placeholder,
  title,
  hint,
  data,
  value,
  hasError,
  isLoading,
  isDisabled,
  keyLabel,
  keyValue,
  onSelect,
  onChangeInput,
  onFinishScroll,
  clearInputValue,
  errorComponent,
  componentSize: size,
  isSearchable,
  inputValue,
  defaultValue,
  optional,
}: Readonly<AutocompleteProps<T>>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const {
    autocomplete: { noDataAvailable, noResultsFound },
  } = COPY_DECK;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [tooltipIsActive, setTooltipIsActive] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const noOptionsComponent = () => {
    if (hasError && errorComponent) {
      return <Styled.ContainerOptions>{errorComponent}</Styled.ContainerOptions>;
    }

    return (
      <Styled.ContainerOptions>
        <Paragraph>{value ? noResultsFound : noDataAvailable}</Paragraph>
      </Styled.ContainerOptions>
    );
  };

  const loadingMessageComponent = () => (
    <Styled.ContainerOptions data-testid="is-loading-dots">
      <LoadingDots />
    </Styled.ContainerOptions>
  );

  const onChange = (newValue: unknown) => {
    onSelect(newValue as T);
  };

  const onMenuOpen = () => {
    if (clearInputValue) {
      clearInputValue();
    }
    setMenuIsOpen(true);
    setTooltipIsActive(false);
  };

  const onInputChange = (valueChanged: string, params: InputActionMeta) => {
    const changeCases: any = {
      'input-change': onChangeInput,
      'input-blur': clearInputValue,
    };

    inputRef.current?.focus();

    if (changeCases[params.action]) changeCases[params.action](valueChanged);
  };

  const placeholderComponent = () => (
    <Styled.PlaceholderContainer isDisabled={isDisabled}>{placeholder}</Styled.PlaceholderContainer>
  );

  const menuComponent = (props: Partial<MenuProps>) => <Menu {...props} />;

  useEffect(() => {
    const currentSelectRef = selectRef.current;
    currentSelectRef?.addEventListener('mouseenter', () => {
      waitForElementToExist('[class*="singleValue"]').then((el) => {
        const element = el as HTMLElement;
        setTooltipIsActive(element && element.offsetWidth < element.scrollWidth);
        setTooltipText(element && (element.textContent as string));
      });
    });

    return () => {
      currentSelectRef?.removeEventListener('mouseenter', () => undefined);
    };
  }, [selectRef]);

  const renderSelect = () => (
    <Select
      value={value}
      data-testid="autocomplete-select-input"
      options={defaultValue ? [defaultValue, ...data] : data}
      isDisabled={isDisabled}
      placeholder={placeholderComponent()}
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(false)}
      filterOption={() => true}
      getOptionLabel={((opt: any) => opt[keyLabel ?? 'label']) as GetOptionLabel<unknown>}
      getOptionValue={((opt: any) => opt[keyValue ?? 'value']) as GetOptionValue<unknown>}
      onMenuScrollToBottom={onFinishScroll}
      inputValue={inputValue}
      onMenuOpen={onMenuOpen}
      isSearchable={isSearchable}
      isLoading={isLoading}
      loadingMessage={loadingMessageComponent}
      noOptionsMessage={noOptionsComponent}
      onInputChange={onInputChange}
      onChange={onChange}
      components={{
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null,
        Menu: menuComponent,
        Option,
        DropdownIndicator,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          width: '100%',
          paddingLeft: '8px',
          boxShadow: 'none',
          border: state.isFocused ? '1px solid black' : '1px solid $neutral300',
          display: 'flex',
          height: '40px',
          cursor: 'text',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '$neutral0',
          fontSize: '16px',
          fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
          fontWeight: '500',
          borderRadius: '8px',
          paddingRight: '18px',
          '&:hover': {
            border: state.isFocused ? '1px solid black' : '1px solid $neutral300',
          },
        }),
      }}
    />
  );

  return (
    <Styled.Container style={{ width: sizes[size] }}>
      {title && (
        <Styled.Title>
          {title} {optional && <Styled.Optional>{optional}</Styled.Optional>}
        </Styled.Title>
      )}
      {hint && <Styled.Hint>{hint}</Styled.Hint>}
      <div ref={selectRef} data-testid="select-ref">
        {tooltipIsActive && /* istanbul ignore next */ !menuIsOpen ? (
          /* istanbul ignore next */
          <Tooltip placement="bottom" text={tooltipText}>
            {renderSelect()}
          </Tooltip>
        ) : (
          renderSelect()
        )}
      </div>
    </Styled.Container>
  );
}

export default Autocomplete;
