import { Card, Input, TextArea } from '@hexa-ui/components';
import styled from 'styled-components';
import { BREAKPOINT_MOBILE } from '../../../../consts/screen';

export const ContainerTaskGroupsCreate = styled.div`
  width: 66%;
  align-self: center;
`;
export const ContainerSelect = styled.div`
  display: flex;
`;

export const InputProfileDisabled = styled(Input)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const InputTaskGroupName = styled(Input)``;

export const TextAreaTaskGroupDescription = styled(TextArea)``;

export const InputTaskGroupMaxTasks = styled(Input)``;

export const WrapperInputMaxTasks = styled.div`
  width: 286px;
`;

export const CardTaskGroupForm = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  box-shadow: 0px 0px 8px 0px #00000029;

  > * {
    width: unset;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    padding: 24px 16px 24px;
  }
`;

export const FormTaskGroupsCreate = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const WrapperLocalModelCheckBox = styled.div`
  margin-top: 16px;

  &:hover {
    cursor: not-allowed;
  }
`;
