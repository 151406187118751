import { Suspense, lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import PageLoading from '../../pages/PageLoading';
import { PATH_BASE, PATH_CREATE, PATH_UPDATE } from './consts/route';
import TaskGroupCreate from './pages/TaskGroupCreate/TaskGroupCreate';
import TaskGroupUpdate from './pages/TaskGroupUpdate/TaskGroupUpdate';

const TaskGroupsList = lazy(() => import('./pages/TaskGroupsList'));

const PageFallback = <PageLoading />;

const routes: RouteProps[] = [
  {
    path: PATH_BASE,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskGroupsList />
      </Suspense>
    ),
  },
  {
    path: `${PATH_CREATE}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskGroupCreate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_UPDATE({ taskGroupId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskGroupUpdate />
      </Suspense>
    ),
  },
];

export default routes;
