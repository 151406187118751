import { LoadingBuzz } from '@hexa-ui/components';
import { DocumentTitle } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { HideAppHeader } from '../../styles/GlobalStyles';

import { PageLoadingContainer } from './PageLoading.styles';

const PageLoading = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <>
      <DocumentTitle>{formatMessage({ id: 'title.pageLoading' })}</DocumentTitle>
      <PageLoadingContainer>
        <LoadingBuzz data-testid="loading-buz-component" size="xlarge" />
      </PageLoadingContainer>
      <HideAppHeader $hasAppHeader={false} $hasBreadcrumb={false} />
    </>
  );
};

export default PageLoading;
