import { Tooltip } from '@hexa-ui/components';
import React from 'react';
import { OptionProps } from 'react-select';
import { Styled } from '../../Autocomplete.styles';

function Option({
  children,
  innerProps,
  innerRef,
  isSelected,
  isFocused,
}: Readonly<Partial<OptionProps>>): React.JSX.Element {
  const { id } = innerProps;
  // Extract the index from the id, which is expected to be in the format 'option-<number>'
  const indexMatch = id.match(/option-(\d+)/);
  const index = indexMatch ? indexMatch[1] : '';

  return (
    <Styled.AutoCompleteOption
      isSelected={isSelected}
      isFocused={isFocused}
      data-testid={`autocomplete-select-option-${index}`}
      {...innerProps}
      ref={innerRef}
    >
      <Tooltip placement="top" text={children as string}>
        {children}
      </Tooltip>
    </Styled.AutoCompleteOption>
  );
}

export default Option;
