import { useAppHeader } from 'admin-portal-shared-services';
import { ThemeProvider } from 'styled-components';
import { FRONTLINE_HUB_CLASSNAME_PREFIX } from '../../consts/theme';
import { GlobalStyles, ResetStyles } from '../../styles/GlobalStyles';
import defaultTheme from '../../styles/themes/default-theme';
import { StyledContainer } from './AppThemeProvider.styles';

interface Props {
  children?: JSX.Element;
}

const AppThemeProvider = ({ children }: Props): JSX.Element => {
  const [appHeaderConfig] = useAppHeader();
  const hasAppHeader =
    !!appHeaderConfig.pageTitle?.trim() ||
    (appHeaderConfig?.countrySelect && appHeaderConfig?.vendorSelect);
  const hasBreadcrumb = appHeaderConfig.breadcrumbConfig?.items?.length > 0;

  return (
    <ThemeProvider theme={defaultTheme}>
      <ResetStyles />
      <GlobalStyles />
      <StyledContainer
        $hasAppHeader={hasAppHeader}
        $hasBreadcrumb={hasBreadcrumb}
        className={FRONTLINE_HUB_CLASSNAME_PREFIX}
        data-testid="styled-container"
      >
        {children}
      </StyledContainer>
    </ThemeProvider>
  );
};

export default AppThemeProvider;
