import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GetGroupDetails, GetGroupsContent } from '../../../../models/group';
import { getGroups } from '../groupsService';

interface UseGetGroupDetailsReturn {
  groups: GetGroupsContent | null;
  isLoading: boolean;
  error: string | null;
}

const useGetGroups = (): UseGetGroupDetailsReturn => {
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const [groups, setGroups] = useState<GetGroupDetails[] | any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getActiveChildGroups = (group: GetGroupDetails) => {
    const [centralizedGroups, decentralizedGroups] = group.childGroups.reduce(
      ([centralized, decentralized], childGroup) => {
        return !childGroup.childGroups.length
          ? [[...centralized, childGroup], decentralized]
          : [centralized, [...decentralized, childGroup]];
      },
      [[], []]
    );

    const activeCentralizedChildGroups = centralizedGroups.filter(
      (childGroup) => !!childGroup.enabled
    );

    const activeDecentralizedChildGroups = decentralizedGroups
      .filter((childGroup) => !!childGroup.enabled)
      .map((activeChildGroup) => {
        const activeChildSubgroups = activeChildGroup.childGroups.filter(
          (childSubgroup) => !!childSubgroup.enabled
        );
        const activeChildSubgroupIds = activeChildSubgroups.map(
          (childSubgroup) => childSubgroup.id
        );
        return {
          ...activeChildGroup,
          childGroups: activeChildSubgroups,
          childGroupIds: activeChildSubgroupIds,
        };
      })
      .filter((activeChildGroup) => !!activeChildGroup.childGroups.length);

    const activeChildGroups = [...activeCentralizedChildGroups, ...activeDecentralizedChildGroups];
    const activeChildGroupIds = activeChildGroups.map((childGroup) => childGroup.id);

    return {
      ...group,
      childGroups: activeChildGroups,
      childGroupIds: activeChildGroupIds,
    };
  };

  const fetchGroups = async () => {
    try {
      setIsLoading(true);
      const { data } = await getGroups();
      setGroups({
        content: data.content
          .filter((group) => group.enabled)
          ?.map((group) => getActiveChildGroups(group)),
      });
    } catch (error) {
      setError(error.message);
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'message.error.operationNotFound' }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return {
    isLoading,
    error,
    groups,
  };
};

export default useGetGroups;
