import MessageMap from '../../../../i18n/i18n.d';

const ptBR: MessageMap = {
  title: {
    page: {
      taskGroupsList: 'Grupos de tarefas',
      taskGroupCreate: 'Criação de grupo de tarefa',
      taskGroupUpdate: 'Edição de grupo de tarefa',
    },
  },
  button: {
    taskGroupButtonCreate: 'Criar grupo',
    taskGroupButtonUpdate: 'Editar grupo',

    taskGroupButtonCancel: 'Voltar',
    editTaskGroup: 'Editar grupo',
    viewGroup: 'Ver grupo',
  },
  fields: {
    label: {
      taskList: {
        selectProfile: 'Perfil',
        taskGroupNextSync: 'As alterações realizadas terão efeito na próxima sincronização.',
        priority: 'Prioridade: ',
        localModel: 'Modelo local',
      },
      taskGroupSelect: 'Grupo de tarefas',
    },
    taskGroupForm: {
      label: {
        profile: 'Perfil',
        name: 'Nome',
        description: 'Descrição',
        maximumTasks: 'Máximo de tarefas neste grupo',
        isLocalModel: 'Tarefas de modelo local',
      },
    },
    placeholder: {
      profile: 'Selecione o perfil',
      name: 'Digite o nome do grupo',
      description: 'Digite a descrição do grupo',
      maximumTasks: 'Ex. 20',
      taskGroupPriority: 'Ex. 3',
    },
  },
  message: {
    error: {
      taskGroupsNotFound:
        'Ainda não foram criados grupos de tarefas. Comece a criá-los em Criar grupo.',
    },
    info: {
      effectivenessMultipleConditions:
        'A funcionalidade selecionada é do tipo cobertura portanto, só pode ser emparelhada com o tipo cobertura.',
      frequencyDisabled:
        'A característica selecionada requer um valor padrão fixo para a frequência.',
    },
  },
  toast: {
    taskGroupError: 'Erro ao carregar os grupos de tarefas. Tente novamente mais tarde.',
    error: {
      taskGroupsPriority:
        'Não foi possível atualizar a prioridade do grupo de tarefas. Tente novamente mais tarde.',
      create: 'Ocorreu um erro durante a criação do grupo de tarefas. Por favor, tente novamente.',
      update:
        'Ocorreu um erro durante a alteração do grupo de tarefas. Por favor, tente novamente.',
    },
    success: {
      taskGroupsPriority: 'A prioridade de {total} grupos de tarefas foi ajustada.',
      create: 'Novo grupo de tarefas criado com sucesso.',
      update: 'Grupo de tarefas alterado com sucesso.',
    },
  },
};

export default ptBR;
