import { useAnalyticsService } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import useGroupData from '../../hooks/useContext/useGroupData/useGroupData';
import { Country } from '../../models/country';
import { useEnvContext } from '../EnvProvider';

interface SegmentProviderProps {
  children: JSX.Element;
  segmentKey?: Record<Country, string>;
}

const SegmentProvider = ({ children, segmentKey }: SegmentProviderProps): JSX.Element => {
  const analyticsService = useAnalyticsService();
  const {
    groupData: { country },
  } = useGroupData();
  const { SEGMENT_KEY } = useEnvContext();
  const segmentKeyCountry = segmentKey ? segmentKey[country] : SEGMENT_KEY[country];

  useEffect(() => {
    if (segmentKeyCountry) {
      analyticsService.load(segmentKeyCountry);
    }
  }, [analyticsService, segmentKeyCountry]);

  return children;
};

export default SegmentProvider;
