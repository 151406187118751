import { configureStore, Store } from '@reduxjs/toolkit';

import activityReducer from './slices/activitySlice';
import userReducer from './slices/userSlice';

export const store: Store = configureStore({
  reducer: {
    activity: activityReducer,
    user: userReducer,
  },
});

export function createMockStore(): Store {
  const mockStore = configureStore({
    reducer: {
      activity: activityReducer,
      user: userReducer,
    },
  });
  return mockStore;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
