import { Suspense, lazy } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import {
  PATH_BASE,
  PATH_CREATE_TASK_PHOTO,
  PATH_CREATE_TASK_SIMPLE,
  PATH_CREATE_TASK_SURVEY,
  PATH_DUPLICATE_TASK_PHOTO,
  PATH_DUPLICATE_TASK_SIMPLE,
  PATH_DUPLICATE_TASK_SURVEY,
  PATH_TASK_LIST,
  PATH_UPDATE_TASK_PHOTO,
  PATH_UPDATE_TASK_SIMPLE,
  PATH_UPDATE_TASK_SURVEY,
} from './consts/route';
import PageLoading from './pages/PageLoading';

const TaskList = lazy(() => import('./pages/TaskList'));

const TaskSimpleCreate = lazy(() => import('./pages/TaskSimple/TaskSimpleCreate'));
const TaskSurveyCreate = lazy(() => import('./pages/TaskSurvey/TaskSurveyCreate'));
const TaskPhotoCreate = lazy(() => import('./pages/TaskPhoto/TaskPhotoCreate'));

const TaskSimpleUpdate = lazy(() => import('./pages/TaskSimple/TaskSimpleUpdate'));
const TaskSurveyUpdate = lazy(() => import('./pages/TaskSurvey/TaskSurveyUpdate'));
const TaskPhotoUpdate = lazy(() => import('./pages/TaskPhoto/TaskPhotoUpdate'));

const TaskSimpleDuplicate = lazy(() => import('./pages/TaskSimple/TaskSimpleDuplicate'));
const TaskSurveyDuplicate = lazy(() => import('./pages/TaskSurvey/TaskSurveyDuplicate'));
const TaskPhotoDuplicate = lazy(() => import('./pages/TaskPhoto/TaskPhotoDuplicate'));

const PageFallback = <PageLoading />;

const routes: RouteProps[] = [
  {
    path: PATH_BASE,
    element: (
      <Suspense fallback={PageFallback}>
        <Navigate to={PATH_TASK_LIST} replace />
      </Suspense>
    ),
  },
  {
    path: PATH_TASK_LIST,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskList />
      </Suspense>
    ),
  },
  {
    path: PATH_CREATE_TASK_SIMPLE,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskSimpleCreate />
      </Suspense>
    ),
  },
  {
    path: PATH_CREATE_TASK_SURVEY,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskSurveyCreate />
      </Suspense>
    ),
  },
  {
    path: PATH_CREATE_TASK_PHOTO,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskPhotoCreate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_UPDATE_TASK_SIMPLE({ taskId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskSimpleUpdate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_UPDATE_TASK_SURVEY({ taskId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskSurveyUpdate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_UPDATE_TASK_PHOTO({ taskId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskPhotoUpdate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_DUPLICATE_TASK_SIMPLE({ taskId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskSimpleDuplicate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_DUPLICATE_TASK_SURVEY({ taskId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskSurveyDuplicate />
      </Suspense>
    ),
  },
  {
    path: `${PATH_DUPLICATE_TASK_PHOTO({ taskId: ':id' })}`,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskPhotoDuplicate />
      </Suspense>
    ),
  },
];

export default routes;
