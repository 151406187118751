import { useAuthenticationService } from 'admin-portal-shared-services';
import { AxiosResponse } from 'axios';
import api from '../../../../../api';
import {
  CreateTaskGroup,
  CreateTaskGroupResponse,
  GetTaskGroupList,
  PriorityTaskGroup,
  TaskGroupListContent,
} from '../../../../../apps/task-groups/models/task-group';
import {
  ENDPOINT_CREATE_TASK_GROUP,
  ENDPOINT_GET_TASK_GROUP,
  ENDPOINT_GET_TASK_GROUP_LIST,
  ENDPOINT_PRIORITY_TASK_GROUP,
  ENDPOINT_UPDATE_TASK_GROUP,
  getServiceDomain,
} from '../../../../../consts/endpoint';
import { isClusterEuUser } from '../../../../../utils/validations';

// eslint-disable-next-line react-hooks/rules-of-hooks
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
const isClusterEu = isClusterEuUser(userCountry);

const getURL = (path: string) => getServiceDomain(isClusterEu) + path;

export const getTaskGroupList = async ({
  signal,
  vendorGroupId,
  profileId,
}: GetTaskGroupList): Promise<AxiosResponse> =>
  api.get({
    url: getURL(ENDPOINT_GET_TASK_GROUP_LIST({ vendorGroupId })),
    config: {
      params: { page: 0, pageSize: -1, profileId },
      signal,
    },
  });

export const getTaskGroupListOrdered = async ({
  signal,
  vendorGroupId,
  profileId,
  excludeLocalModels,
}: GetTaskGroupList): Promise<TaskGroupListContent[]> => {
  const response = await getTaskGroupList({ signal, vendorGroupId, profileId });

  let taskGroupsContent: TaskGroupListContent[] = response.data?.content || [];

  const hasContent = taskGroupsContent.length > 0;
  if (hasContent) {
    taskGroupsContent?.sort(function (a, b) {
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;
      return 0;
    });

    if (excludeLocalModels) {
      taskGroupsContent = taskGroupsContent.filter((taskGroup) => !taskGroup.isLocalModel);
    }
  }

  return taskGroupsContent;
};

export const createTaskGroup = async ({
  body,
}: CreateTaskGroup): Promise<AxiosResponse<CreateTaskGroupResponse>> =>
  api.post({
    url: `${getURL(ENDPOINT_CREATE_TASK_GROUP)}`,
    body,
  });

export const getTaskGroup = async ({
  taskGroupId,
  vendorGroupId,
  signal,
}): Promise<AxiosResponse> =>
  api.get({
    url: `${getURL(ENDPOINT_GET_TASK_GROUP({ taskGroupId }))}`,
    config: {
      headers: {
        vendorGroupId,
      },
      signal,
    },
  });

export const patchTaskGroup = async ({
  body,
  taskGroupId,
  vendorGroupId,
}): Promise<AxiosResponse> =>
  api.patch({
    url: `${getURL(ENDPOINT_UPDATE_TASK_GROUP({ taskGroupId }))}`,
    body,
    config: {
      headers: {
        vendorGroupId,
      },
    },
  });

export const priorityTaskGroup = async ({ body }: PriorityTaskGroup): Promise<AxiosResponse> =>
  api.patch({
    url: `${getURL(ENDPOINT_PRIORITY_TASK_GROUP)}`,
    body,
  });
