import React from 'react';
import { DropdownIndicatorProps } from 'react-select';
import { Styled } from '../../Autocomplete.styles';

function DropdownIndicator({
  innerProps,
}: Readonly<Partial<DropdownIndicatorProps>>): React.JSX.Element {
  return (
    <div
      data-testid="autocomplete-select-arrow-container"
      {...innerProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Styled.AutoCompleteChevronDown data-testid="autocomplete-select-arrow" />
    </div>
  );
}

export default DropdownIndicator;
