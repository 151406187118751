import React from 'react';
import ReactDOM from 'react-dom';
import { navigateToUrl } from 'single-spa';
import singleSpaReact from 'single-spa-react';
import App from './App';
import FallbackError from './components/FallbackError';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    return <FallbackError error={error} resetErrorBoundary={() => navigateToUrl('/frontline')} />;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
