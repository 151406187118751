import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { Env } from '../../models/env';
import apiHandlers from './handlers/apiHandlers';

export const rootHandlers = (mock: MockAdapter): void => {
  apiHandlers(mock);
};

export const enableMockAdapter = (axios: AxiosInstance): void => {
  const isMockEnable = localStorage.getItem('mock-adapter') === 'true';
  const currentEnv = JSON.parse(localStorage.getItem('portal-config'))?.ENV;
  const isLowEnv = [Env.Dev, Env.Qa, Env.Uat].includes(currentEnv);

  if (isMockEnable && isLowEnv) {
    const mock = new MockAdapter(axios as any, {
      onNoMatch: 'passthrough',
      delayResponse: 500,
    });
    rootHandlers(mock);
  }
};
