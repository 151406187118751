import styled, { createGlobalStyle, css } from 'styled-components';
import { BREAKPOINT_MOBILE } from '../consts/screen';
import { FRONTLINE_HUB_CLASSNAME_PREFIX } from '../consts/theme';
import { StyledContainer } from '../providers/AppThemeProvider/AppThemeProvider.styles';

export const ResetStyles = createGlobalStyle`
    .${FRONTLINE_HUB_CLASSNAME_PREFIX} * {
    }

    button {
        background: none;
        border: none;
        padding: 0;
    }
`;

export const GlobalStyles = createGlobalStyle`
    .${FRONTLINE_HUB_CLASSNAME_PREFIX} * {
        box-sizing: border-box;
    }

    img {
        display: block;
        max-width: 100%;
    }
`;

export const HideAppHeader = createGlobalStyle`
    #mfe-content-header {
        display: none;
    }

    ${StyledContainer} {
        min-height: calc(100vh - 56px);

        @media (max-width: ${BREAKPOINT_MOBILE}) {
            min-height: calc(100vh - 112px);
        }
    }
`;

export const HideBodyScrollbar = createGlobalStyle`
    body {
        overflow: hidden !important;
    }
`;

export const WindowShadow = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 500;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export const CustomScrollbar = css`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #d9d9d940;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 8px;
  }
`;
