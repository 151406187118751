import { Paragraph, SkeletonLoader, Tooltip } from '@hexa-ui/components';
import { ChevronDown, Edit2, Info } from '@hexa-ui/icons';
import { CSSProperties, useState } from 'react';
import { useIntl } from 'react-intl';
import ConfigureOperationModal from '../ConfigureOperationModal/ConfigureOperationModal';
import {
  ContainerOperationsButton,
  InfoIconContainer,
  OperationButton,
  OperationContainer,
  OperationFrame,
  OperationLabel,
} from './OperationSelect.styles';

interface OperationSelectProps {
  organization: string;
  seller?: string;
  disabled?: boolean;
  labelId: string;
  profileView?: boolean;
  hasInformationIcon?: boolean;
  width?: CSSProperties['width'];
}

const OperationSelect = ({
  organization,
  seller,
  disabled,
  labelId,
  profileView,
  hasInformationIcon,
  width = 'max-content',
}: OperationSelectProps): JSX.Element => {

  const { formatMessage } = useIntl();

  const [modal, setModal] = useState<boolean>(false);

  const isLoading = !organization;

  const handleOperationClick = () => {
    if (!disabled) {
      setModal(true);
    }
  };

  return (
    <OperationFrame>
      <OperationLabel>{labelId}</OperationLabel>
      <OperationContainer data-testid="operation-container" disabled={disabled} width={width}>
        {!isLoading ? (
          <ContainerOperationsButton>
            <OperationButton type="button" disabled={disabled} onClick={handleOperationClick}>
              <Paragraph weight="medium">
                {organization} {seller ? `/ ${seller}` : ''}
              </Paragraph>
              {profileView ? <ChevronDown size="medium" /> : <Edit2 size="medium" />}
            </OperationButton>
            {hasInformationIcon && (
              <InfoIconContainer data-testId="operation-select-info">
                <Tooltip
                  placement="left"
                  text={formatMessage({ id: 'formField.label.task.list.selectOperationTooltip' })}
                >
                  <Info size="large" />
                </Tooltip>
              </InfoIconContainer>
            )}
          </ContainerOperationsButton>
        ) : (
          <SkeletonLoader height="41.6px" />
        )}
      </OperationContainer>
      <ConfigureOperationModal
        isToggleEnabled={false}
        isOpen={modal}
        onCancel={() => setModal(false)}
        onClose={() => setModal(false)}
      />
    </OperationFrame>
  );
};

export default OperationSelect;
