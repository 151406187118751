import { styled } from '@hexa-ui/theme';

const WrapperDialogButtons = styled('div', {
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
});

const BodyContainer = styled('div', {
  height: '150px',
  width: '644px',
});

const InputContainer = styled('div', {
  display: 'flex',
  gap: '16px',
  variants: {
    hasSellers: {
      false: {
        gridTemplateColumns: '1fr',
      },
    },
  },
});

const Styled = {
  WrapperDialogButtons,
  BodyContainer,
  InputContainer,
};

export default Styled;
