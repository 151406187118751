import { useAuthenticationService } from 'admin-portal-shared-services';
import { AxiosResponse } from 'axios';
import {
  ENDPOINT_ACTIVITIES,
  ENDPOINT_CREATE_TASK_PHOTO,
  ENDPOINT_CREATE_TASK_SURVEY,
  ENDPOINT_DELETE_ACTIVITY_TEMPLATE,
  ENDPOINT_GET_ACTIVITY,
  ENDPOINT_GET_ACTIVITY_PROFILES,
  ENDPOINT_GET_ACTIVITY_TYPES,
  ENDPOINT_GET_CATEGORIES,
  ENDPOINT_GET_FEATURE_MULTI_SELECT_METADATA,
  ENDPOINT_GET_FEATURE_VALUE,
  ENDPOINT_GET_FREQUENCIES,
  ENDPOINT_GET_GROUPED_FEATURES,
  ENDPOINT_GET_LOCAL_MODELS,
  ENDPOINT_GET_SIMPLE_COVERAGE_PRODUCTS_LIST,
  ENDPOINT_GET_SPONSORS,
  ENDPOINT_GET_SUBTYPES,
  ENDPOINT_GET_VISION_JOB_ID,
  ENDPOINT_SET_ACTIVITY_PRIORITY,
  ENDPOINT_SET_ACTIVITY_PRIORITY_SYNC,
  SERVICE_FRONTLINE_METADATA_CONFIGURATION,
  SERVICE_VISION_CONFIGURATION,
  getServiceDomain,
} from '../../../consts/endpoint';
import {
  ActivitySubtypeList,
  CreateActivityPhoto,
  CreateActivitySimple,
  CreateActivitySimpleResponse,
  CreateActivitySurvey,
  GetFeatureMultiselectMetadata,
  GetFeaturesMultiselectMetadataResponse,
  GetLocalModels,
  GetSimpleCoverageProductsList,
  GetSubtypes,
  GetVisionJobId,
  LocalModelList,
  PayloadSimpleCoverageProductsList,
  UpdateActivityResponse,
  UpdateAndSyncTaskPriority,
  VisionJobId,
} from '../../../models/activity';
import { GetFrequency } from '../../../models/frequency';
import { isClusterEuUser } from '../../../utils/validations';
import api from '../../api';

// eslint-disable-next-line react-hooks/rules-of-hooks
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
const isClusterEu = isClusterEuUser(userCountry);

export const getFrequencies = async ({ signal }: GetFrequency): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_FREQUENCIES}`,
    config: {
      signal,
    },
  });

export const createActivity = async ({
  body,
  vendorGroupId,
}: CreateActivitySimple): Promise<AxiosResponse<CreateActivitySimpleResponse>> =>
  api.post({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_ACTIVITIES}`,
    body,
    config: {
      headers: {
        vendorGroupId,
      },
    },
  });

export const createActivitySurvey = async ({
  body,
  vendorGroupId,
}: CreateActivitySurvey): Promise<AxiosResponse<UpdateActivityResponse>> =>
  api.post({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_CREATE_TASK_SURVEY}`,
    body,
    config: {
      headers: {
        vendorGroupId,
      },
    },
  });

export const createActivityPhoto = async ({
  body,
}: CreateActivityPhoto): Promise<AxiosResponse<UpdateActivityResponse>> =>
  api.post({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_CREATE_TASK_PHOTO}`,
    body,
  });

export const getCategories = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_CATEGORIES}`,
    config: {
      signal,
    },
  });

export const getActivityTypes = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY_TYPES}`,
    config: {
      signal,
    },
  });

  export const getGroupedFeatures = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_GROUPED_FEATURES}`,
    config: {
      params: {
        pageSize: -1,
      },
      signal,
    },
  });

export const getFeatureValues = async ({ selectedPeriod, selectedFeature, signal }): Promise<AxiosResponse> => 
  api.get({
    url: `${getServiceDomain(isClusterEu)}${ENDPOINT_GET_FEATURE_VALUE}`,
    config: {
      params:{
        featureId: `${selectedPeriod}_${selectedFeature}`,
        pageSize: 1,
      },
      signal,
    }
  });


export const getFeatureMultiselectMetadata = async ({
  signal,
  featureType,
  page,
  metadataId,
}: GetFeatureMultiselectMetadata): Promise<AxiosResponse<GetFeaturesMultiselectMetadataResponse>> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_FEATURE_MULTI_SELECT_METADATA}`,
    config: {
      params: {
        pageSize: 100,
        type: featureType,
        page,
        metadataId: metadataId?.toString() || null,
      },
      signal,
    },
  });

export const getActivityProfiles = async ({ signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY_PROFILES}`,
    config: {
      signal,
    },
  });

export const getActivityList = async ({
  templateName,
  page,
  pageSize,
  profileId,
  activityTemplateGroupId,
  signal,
}): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_ACTIVITIES}`,
    config: {
      params: {
        templateName,
        profileId,
        page,
        pageSize,
        order: 'priority',
        activityTemplateGroupId,
      },
      signal,
    },
  });

export const getActivity = async ({ activityId, signal }): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_GET_ACTIVITY({ activityId })}`,
    config: {
      params: {
        activityTemplateId: activityId,
      },
      signal,
    },
  });

export const setPriority = async ({ body, params }): Promise<AxiosResponse> =>
  api.patch({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_SET_ACTIVITY_PRIORITY}`,
    body,
    config: {
      params,
    },
  });

export const prioritySync = async ({
  profileId,
  activityTemplateGroupId,
}): Promise<AxiosResponse> =>
  api.patch({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_SET_ACTIVITY_PRIORITY_SYNC}`,
    config: {
      params: {
        activityTemplateGroupId,
      },
    },
  });

export const deleteActivityTemplate = async ({ activityTemplateId }): Promise<AxiosResponse> =>
  api.delete({
    url: `${getServiceDomain(
      isClusterEu
    )}${SERVICE_FRONTLINE_METADATA_CONFIGURATION}/${ENDPOINT_DELETE_ACTIVITY_TEMPLATE({
      activityId: activityTemplateId,
    })}`,
    config: {
      params: {
        activityTemplateId,
      },
    },
  });

export type TGetSponsors = {
  vendorGroupId: string;
  page: number;
  signal: any;
  search: string;
};

export const getSponsors = async ({
  vendorGroupId,
  page,
  signal,
  search,
}: TGetSponsors): Promise<AxiosResponse> =>
  api.get({
    url: `${getServiceDomain(isClusterEu)}${ENDPOINT_GET_SPONSORS}?${vendorGroupId}`,
    config: {
      signal,
      params: {
        pageSize: 500,
        page,
        ...(search && { search: search }),
      },
    },
  });

export const getActivitySubtypes = async ({
  signal,
  vendorGroupId,
}: GetSubtypes): Promise<AxiosResponse<ActivitySubtypeList>> => {
  return api.get({
    url: `${getServiceDomain(isClusterEu)}${ENDPOINT_GET_SUBTYPES}`,
    config: {
      signal,
      params: {
        vendorGroupId,
      },
    },
  });
};

export const getSimpleCoverageProductsList = async ({
  acceptLanguage,
  xAppName,
  originSystem,
  body,
  country,
}: GetSimpleCoverageProductsList): Promise<AxiosResponse<PayloadSimpleCoverageProductsList>> =>
  api.post({
    url: `${getServiceDomain(isClusterEu)}/${ENDPOINT_GET_SIMPLE_COVERAGE_PRODUCTS_LIST}`,
    config: {
      headers: {
        'X-App-Name': xAppName,
        'Accept-Language': acceptLanguage,
        originSystem,
        country,
      },
    },
    body,
  });

export const getLocalModels = async ({
  active,
  signal,
}: GetLocalModels): Promise<AxiosResponse<LocalModelList>> => {
  return api.get({
    url: `${getServiceDomain(isClusterEu)}${ENDPOINT_GET_LOCAL_MODELS}`,
    config: {
      signal,
      params: {
        active,
      },
    },
  });
};

export const getVisionJobId = async ({
  visionJobId,
  signal,
}: GetVisionJobId): Promise<AxiosResponse<VisionJobId>> =>
  api.get({
    url: `${SERVICE_VISION_CONFIGURATION(isClusterEu)}/${ENDPOINT_GET_VISION_JOB_ID({
      visionJobId,
    })}`,
    config: {
      signal,
    },
  });

export const updateAndSyncTaskPriority = async ({
  currentPriority,
  newPriority,
  templateId,
  profileId,
  activityTemplateGroupId,
}: UpdateAndSyncTaskPriority): Promise<void> => {
  await setPriority({
    body: {
      currentPriority,
      newPriority,
      activityTemplateId: templateId,
    },
    params: {
      profileId,
      activityTemplateGroupId,
    },
  });

  try {
    await prioritySync({
      profileId,
      activityTemplateGroupId,
    });
  } catch {
    await setPriority({
      body: {
        currentPriority,
        newPriority,
        activityTemplateId: templateId,
      },
      params: {
        profileId,
        activityTemplateGroupId,
      },
    });

    throw new Error('Unable to sync task priority');
  }
};
